section#portfolio {
    min-height: 1100px;
}

.contactArea{
    z-index: 9999999;
}

.contact {
    background: #333;
}

.contact-form-card, .contact-info-card{
    margin-top: 100px;
}